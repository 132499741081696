<template>
  <div>
    <div class="d-flex flex-row align-items-center justify-content-between mb-8">
      <h2 class="font-weight-bold my-2 mr-5">
        Gezilecek Yer Oluştur
      </h2>
      <div class="d-flex flex-row align-items-center">
      <router-link
        :to="{ name: 'attractions', params: { destination: destinationId === undefined ? 'all' :  destinationId } }"
        v-slot="{ href }"
      >
        <a :href="href" class="btn btn-light-primary btn-fixed-height font-weight-bold px-2 px-lg-5">
          <span class="d-none d-md-inline">Gezilecek Yerler</span>
        </a>
      </router-link>
      </div>
    </div>
    <CreateAttractionWizard :destination-id="destinationId === undefined ? '' :  destinationId"></CreateAttractionWizard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CreateAttractionWizard from "@/view/pages/attraction/components/CreateAttractionWizard";

export default {
  name: "CreateAttraction",
  components: {
    CreateAttractionWizard
  },
  data() {
    return {
      destinationId: this.$route.params.destination
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "CreateAttraction" }]);
  },
};
</script>